import React from 'react'
import { Card } from 'react-bootstrap'

import useCurrentCompanyOrFail from '../../hooks/useCurrentCompanyOrFail'
import { useCurrentUserOrFail } from '../../hooks/useCurrentUserOrFail'
import companyService from '../../lib/services/companyService'

/**
 * ⚠️⚠️⚠️ If making changes in here, you should also make changes
 * in the corresponding email template in the RQ-API project
 */
const InviteReferralPartnerEmailPreview: React.FC = () => {
  const currentUser = useCurrentUserOrFail()
  const currentCompany = useCurrentCompanyOrFail()

  const inviteLink = companyService.getInvitationLink(
    currentCompany.referralCode,
  )

  return (
    <div className="pt-3">
      <Card className="p-5 m-0 box-shadow-lg">
        <p>Hello,</p>

        <p>
          <b>{currentUser.fullName}</b> from <b>{currentCompany.name}</b> has
          invited you to join them on RQ, the professional collaboration
          platform.
        </p>

        <p>
          To get started, please visit this link to set up your account:{' '}
          <a href={inviteLink} target="_blank" rel="noreferrer">
            {inviteLink}
          </a>
        </p>

        <p>RQ is free for your firm to use with {currentCompany.name}.</p>

        <p>
          Kind regards,
          <br />
          <strong>The RQ Team</strong>
        </p>
      </Card>
    </div>
  )
}

export default InviteReferralPartnerEmailPreview
